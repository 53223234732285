@import '../../../styles/customMediaQueries.css';

.root {
    composes: marketplaceModalBaseStyles from global;
    padding-top: 70px;
    /* min-height: 80vh; */

    @media (--viewportMedium) {
        flex-basis: 1024px;
    }
}


.modalButtons {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.modalMessage {
    font-weight: 300;
}

.modalContent {
    flex-grow: 1;
}

/* Icon of the modal */
.modalIcon {
    composes: marketplaceModalIconStyles from global;
}

/* Title of the modal */
.modalTitle {
    composes: marketplaceModalTitleStyles from global;
}

.reviewee {
    white-space: nowrap;
}

/* Paragraph for the Modal */
.modalMessage {
    composes: marketplaceModalParagraphStyles from global;
}

.delete {
    composes: hover from global;
    width: 32px;
    margin-top: 20px;
}

.galleryContainer {
    width: 100%;
    flex-grow: 1;
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr 1fr;
}

.image {
    position: relative;
    width: 100%;
    height: 300px;
}